.global-error-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.global-error-wrap>.stars>.star {
    position: absolute;
    bottom: -20px;
    transform: rotate(0deg);
    width: 20px;
    height: 20px;
    background: no-repeat center center;
    background-size: contain;
    animation: rotating 1.5s linear infinite, twinkle 1.5s ease-in-out infinite;
    z-index: -1;
}

.global-error-wrap>.stars>.star:nth-child(1) {
    animation: rotating 1.2s linear infinite, twinkle 1.2s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(2) {
    animation: rotating 2.1s linear infinite, twinkle 2.1s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(3) {
    animation: rotating 1.8s linear infinite, twinkle 1.8s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(4) {
    animation: rotating 1.5s linear infinite, twinkle 1.5s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(5) {
    animation: rotating 2.3s linear infinite, twinkle 2.3s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(6) {
    animation: rotating 1.7s linear infinite, twinkle 1.7s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(7) {
    animation: rotating 2s linear infinite, twinkle 2s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(8) {
    animation: rotating 1.4s linear infinite, twinkle 1.4s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(9) {
    animation: rotating 1.9s linear infinite, twinkle 1.9s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(10) {
    animation: rotating 1.6s linear infinite, twinkle 1.6s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(11) {
    animation: rotating 1.2s linear infinite, twinkle 1.2s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(12) {
    animation: rotating 2.1s linear infinite, twinkle 2.1s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(13) {
    animation: rotating 1.8s linear infinite, twinkle 1.8s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(14) {
    animation: rotating 1.5s linear infinite, twinkle 1.5s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(15) {
    animation: rotating 2.3s linear infinite, twinkle 2.3s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(16) {
    animation: rotating 1.7s linear infinite, twinkle 1.7s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(17) {
    animation: rotating 2s linear infinite, twinkle 2s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(18) {
    animation: rotating 1.4s linear infinite, twinkle 1.4s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(19) {
    animation: rotating 1.9s linear infinite, twinkle 1.9s ease-in-out infinite;
}

.global-error-wrap>.stars>.star:nth-child(20) {
    animation: rotating 1.6s linear infinite, twinkle 1.6s ease-in-out infinite;
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes twinkle {

    0%,
    100% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}